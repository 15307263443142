import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DragAndDrop } from "@vp/shared-capabilities-component-library/components";
import {
  Box,
  Button,
  Icon,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
  Spinner,
  Typography,
} from "@vp/swan";
import { QuantityContext } from "../../../../root/contexts/QuantityContext/QuantityContext";
import { GroupFormContext } from "../../context/GroupFormContext";
import { GroupsAndTeamsContext } from "../../../context/GroupsAndTeamsContext";
import { ExamplePlaceHoldersTable } from "../ExamplePlaceHoldersTable";
import { ConfirmationDialogModal } from "../ConfirmationDialogModal";
import { useAlertState } from "../../../../root/contexts/AlertContext";
import { buildGroupsAndTeamMembersFromUploadData } from "../../../../../utils/uploadTeamMemberHelper";
import { useExcelUpload } from "../../../../root/contexts/ExcelUploadContext";
import { UploadStatus } from "../../../../../types/Upload";
import { getCulture } from "../../../../../utils/LocalizationHelpers";
import { useBffData } from "../../../../../hooks/queries";
import { useTracking } from "../../../../root/contexts/TrackingContext";
import {
  TrackingEventDetail,
  TrackingLabel,
} from "../../../../../types/TrackingConfiguration";

export const UploadButton: React.FC<{}> = () => {
  const { t } = useTranslation("translation");
  const culture = getCulture();
  const [hasFiredAlert, setHasFiredAlert] = useState(false);
  const [customerFile, setCustomerFile] = useState<File>();
  const [hasParsedExcel, setHasParsedExcel] = useState(false);
  const [hasSavedUpload, setHasSavedUpload] = useState(true);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isUploadConfirmationModalOpen, setIsUploadConfirmationModalOpen] =
    useState(false);

  const { data: bffData } = useBffData();
  const {
    minimumOrderQuantity,
    productKey,
    productVersion,
    mpvId,
    productName,
  } = bffData!;
  const { formValidity, setMemberFormsToValidate, clearValidity } =
    useContext(GroupFormContext);
  const {
    availableSizes,
    quantitySelectionListValues,
    isYsdDesign,
    groupsAndTeamsMembers,
    updateGroupsAndTeamsMembers,
    teamsPlaceholders,
    saveGroupsAndTeamsConfig,
  } = useContext(GroupsAndTeamsContext);
  const { updateQuantity } = useContext(QuantityContext);
  const { setError, setWarning, setSuccess, clearAlerts } = useAlertState();
  const { parseExcel, data, isFetching, uploadFailed } = useExcelUpload();
  const {
    trackFlyOutViewed,
    trackFlyOutClosed,
    trackFlyOutClicked,
    trackMessageDisplayed,
  } = useTracking();

  const triggerUploadAlert = (alertType: UploadStatus) => {
    switch (alertType) {
      case UploadStatus.SUCCESS:
        setSuccess(t("alert-messages.upload-success"));
        break;
      case UploadStatus.UPLOAD_FAILURE:
        setError(t("alert-messages.upload-failed"));
        break;
      case UploadStatus.UPLOAD_ISSUES:
        setWarning(t("alert-messages.upload-warning"));
        break;
    }

    setIsUploadModalOpen(false);

    // This prevents the alert dialog from firing when the form validity changes after the
    // customer has started to make changes to the team members post-upload
    setHasFiredAlert(true);
  };

  const onClickUploadCTA = () => {
    setIsUploadModalOpen(true);
    trackFlyOutViewed(
      TrackingLabel.UPLOAD,
      mpvId,
      productName,
      productKey,
      productVersion,
      TrackingEventDetail.UPLOAD,
    );
  };

  const onCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    trackFlyOutClosed(
      TrackingLabel.UPLOAD,
      mpvId,
      productName,
      productKey,
      productVersion,
      TrackingEventDetail.UPLOAD,
    );
  };

  const openUploadConfirmationModal = () => {
    setIsUploadConfirmationModalOpen(true);
    trackFlyOutViewed(
      TrackingLabel.UPLOAD_CONFIRMATION,
      mpvId,
      productName,
      productKey,
      productVersion,
      TrackingEventDetail.UPLOAD,
    );
  };

  const onCloseUploadConfirmationModal = () => {
    setIsUploadConfirmationModalOpen(false);
    trackFlyOutClosed(
      TrackingLabel.UPLOAD_CONFIRMATION,
      mpvId,
      productName,
      productKey,
      productVersion,
      TrackingEventDetail.UPLOAD,
    );
  };

  const onFileSelected = (file: File) => {
    // Note: we are not tracking a button click specifically, instead waiting for file selection.
    // This is done to ensure drag and drop actions are also tracked.
    trackFlyOutClicked(
      TrackingLabel.UPLOAD,
      mpvId,
      productName,
      productKey,
      productVersion,
      TrackingEventDetail.UPLOAD,
    );

    // If there's any teams data on the form, display the warning to the customer that it will be replaced
    // with the contents of the uploaded file
    const firstMemberHasContent = teamsPlaceholders
      .map(
        (placeholder) =>
          groupsAndTeamsMembers[0].placeholderValues[placeholder.key] !== "",
      )
      .includes(true);

    if (groupsAndTeamsMembers.length > 1 || firstMemberHasContent) {
      openUploadConfirmationModal();
      setCustomerFile(file);
    } else parseExcel(file);
    return {};
  };

  const onConfirmClick = () => {
    if (customerFile) {
      parseExcel(customerFile);

      // To prevent any potential issues with future uploads, we want to reset this as soon
      // as we use it.
      setCustomerFile(undefined);
    }
    trackFlyOutClicked(
      TrackingLabel.UPLOAD_CONFIRMATION,
      mpvId,
      productName,
      productKey,
      productVersion,
      TrackingEventDetail.UPLOAD,
    );
  };

  useEffect(() => {
    if (hasParsedExcel) {
      const formValidityValues = Object.values(formValidity);

      if (formValidityValues.length > 0 && !hasFiredAlert) {
        const rowsUploaded = formValidityValues.length;
        let numInvalidRows = formValidityValues.filter(
          (value) => !value,
        ).length;

        if (numInvalidRows === 0) {
          triggerUploadAlert(UploadStatus.SUCCESS);
          trackMessageDisplayed(
            TrackingLabel.UPLOAD_SUCCESS,
            mpvId,
            productName,
            productKey,
            productVersion,
            `${TrackingEventDetail.UPLOAD_SUCCESS_PREFIX}${rowsUploaded}`,
          );
        } else {
          triggerUploadAlert(UploadStatus.UPLOAD_ISSUES);
          trackMessageDisplayed(
            TrackingLabel.UPLOAD_ERROR,
            mpvId,
            productName,
            productKey,
            productVersion,
            `${TrackingEventDetail.UPLOAD_ERROR_PREFIX} ${numInvalidRows} invalid rows of ${rowsUploaded} total rows`,
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasParsedExcel, formValidity, hasFiredAlert]);

  useEffect(() => {
    if (!hasSavedUpload) {
      const wholeFormValidity = Object.values(formValidity);
      const isFormValid =
        wholeFormValidity.length > 0 &&
        wholeFormValidity.every((value) => value);
      if (hasFiredAlert && isFormValid) {
        saveGroupsAndTeamsConfig();
        setHasSavedUpload(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSavedUpload, hasFiredAlert, formValidity]);

  useEffect(() => {
    if (isFetching) {
      setHasParsedExcel(false);
      setHasFiredAlert(false);
      return;
    } else {
      setIsUploadModalOpen(false);

      if (uploadFailed) {
        clearAlerts();
        triggerUploadAlert(UploadStatus.UPLOAD_FAILURE);
        trackMessageDisplayed(
          TrackingLabel.UPLOAD_ERROR,
          mpvId,
          productName,
          productKey,
          productVersion,
          `${TrackingEventDetail.UPLOAD_ERROR_PREFIX} File upload failure`,
        );
      }
    }

    if (!data || data.length === 0) return;

    // To prevent this from constantly getting called anytime a customer engages with the form after upload, we use
    // hasParsedExcel to ensure this is only processed once -- when the excel file has been properly parsed
    if (!hasParsedExcel) {
      // Remove old validity data from previous form contents & reset save status
      clearValidity();
      setHasSavedUpload(false);

      // Remove any previous alerts from the page.
      clearAlerts();

      const uploadedGroupsAndTeamsMembers =
        buildGroupsAndTeamMembersFromUploadData({
          entityCollection: data,
          teamsPlaceholders,
          availableSizes,
        });

      updateGroupsAndTeamsMembers(uploadedGroupsAndTeamsMembers);

      // Manually validate the quantity as the normal input-based validation doesn't support
      // many quantity fields changing at once
      let newQuantityValue: Record<string, number> | number;
      if (availableSizes.length > 0) {
        const sizeQuantities = {} as Record<string, number>;
        uploadedGroupsAndTeamsMembers.forEach((member) => {
          const selectedSize = member.selectedOptions["Size"];
          if (!sizeQuantities[selectedSize]) sizeQuantities[selectedSize] = 0;
          sizeQuantities[selectedSize] += member.qty;
        });
        newQuantityValue = sizeQuantities;
      } else {
        newQuantityValue = 0;
        uploadedGroupsAndTeamsMembers.forEach(
          (member) => ((newQuantityValue as number) += member.qty),
        );
      }
      updateQuantity(newQuantityValue);

      // Subscribe new members to subsequent quanity validation checks:
      setMemberFormsToValidate(
        uploadedGroupsAndTeamsMembers.map((member) => member.id),
      );

      setHasParsedExcel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hasParsedExcel, isFetching, teamsPlaceholders, uploadFailed]);

  return (
    <Box paddingX={8} pb={6}>
      <Button
        onClick={onClickUploadCTA}
        width="standard"
        skin="secondary"
        marginLeft={{ sm: 3 }}
        iconPosition="left"
      >
        <Icon iconType="upload" />
        {t("upload.cta.value")}
      </Button>
      <ModalDialog
        isOpen={isUploadModalOpen}
        onRequestDismiss={onCloseUploadModal}
        variant="panel-right"
        onlyRenderWhenOpen
      >
        <ModalDialogContent>
          <ModalDialogNav>
            <ModalDialogCloseButton accessibleText={t("close")} />
          </ModalDialogNav>
          <ModalDialogHeader>
            <ModalDialogTitle>
              {isYsdDesign ? t("upload.title-team") : t("upload.title-group")}
            </ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>
            {isFetching ? (
              <Box align="center" py={12}>
                <Spinner accessibleText={t("upload.uploading")} showText />
              </Box>
            ) : (
              <DragAndDrop
                backgroundColor="strong"
                buttonCaption={t("upload.cta.value")}
                acceptedFileTypes={["xls", "xlsx", "csv"]}
                locale={culture.toLowerCase()}
                enableErrorManagement={true}
                onFileSelected={onFileSelected}
                onFileError={(error) => {
                  return {};
                }}
              />
            )}
            <Typography fontSkin="body-standard" mt={4} mb="between-sections">
              {isYsdDesign
                ? t("upload.instructions-team")
                : t("upload.instructions-group")}
            </Typography>
            <ExamplePlaceHoldersTable
              placeholders={teamsPlaceholders}
              isSizedGood={availableSizes.length > 0}
              sizes={availableSizes}
              quantityOptions={quantitySelectionListValues}
              minimumOrderQuantity={minimumOrderQuantity}
            />
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
      <ConfirmationDialogModal
        dialogType={"UploadReplace"}
        isOpen={isUploadConfirmationModalOpen}
        onCloseModal={onCloseUploadConfirmationModal}
        onConfirmClick={onConfirmClick}
      />
    </Box>
  );
};
