import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  ModalDialog,
  ModalDialogButtons,
  ModalDialogHeader,
  ModalDialogContent,
  ModalDialogCloseButton,
  ModalDialogFooter,
  ModalDialogBody,
  ModalDialogNav,
  useScreenClass,
} from "@vp/swan";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";

type DialogType = "DeleteTeammate" | "UploadReplace";

type ConfirmationDialogModalProps = {
  dialogType: DialogType;
  isLoading?: boolean;
  isOpen: boolean;
  onCloseModal: any;
  onConfirmClick: () => void;
};

type DialogCustomization = {
  titleLabel: string;
  bodyLabel: string;
  confirmLabel: string;
  cancelLabel: string;
  ariaLabel: string;
};

export const ConfirmationDialogModal: React.FC<
  ConfirmationDialogModalProps
> = ({
  dialogType,
  isLoading = false,
  isOpen,
  onCloseModal,
  onConfirmClick,
}) => {
  const { t } = useTranslation("translation");
  const isMobileView = useScreenClass() === "xs";
  const { isYsdDesign } = useContext(GroupsAndTeamsContext);

  const handleOnClick = () => {
    onConfirmClick();
    onCloseModal();
  };

  const getDialogText = (): DialogCustomization => {
    const response: DialogCustomization = {
      titleLabel: "",
      bodyLabel: "",
      confirmLabel: "",
      cancelLabel: "",
      ariaLabel: "",
    };
    switch (dialogType) {
      case "DeleteTeammate":
        response.titleLabel = t(
          "groups-and-teams.confirmation-dialog-title-remove",
        );
        if (isYsdDesign) {
          response.ariaLabel = t("groups-and-teams.remove-teammate");
          response.bodyLabel = t("groups-and-teams.delete-modal-header");
          response.cancelLabel = t("groups-and-teams.delete-modal-no-button");
          response.confirmLabel = t("groups-and-teams.delete-modal-yes-button");
        } else {
          response.ariaLabel = t("groups-and-teams.remove-person");
          response.bodyLabel = t("groups-and-teams.delete-person-modal-header");
          response.cancelLabel = t(
            "groups-and-teams.delete-person-modal-no-button",
          );
          response.confirmLabel = t(
            "groups-and-teams.delete-person-modal-yes-button",
          );
        }
        break;
      case "UploadReplace":
        if (isYsdDesign) {
          response.titleLabel = t(
            "groups-and-teams.confirmation-dialog-title-replace-team",
          );
          response.bodyLabel = t("upload.dialog.label-team");
        } else {
          response.titleLabel = t(
            "groups-and-teams.confirmation-dialog-title-replace-group",
          );
          response.bodyLabel = t("upload.dialog.label-group");
        }
        response.ariaLabel = response.bodyLabel;
        response.cancelLabel = t("upload.dialog.keep-existing");
        response.confirmLabel = t("upload.dialog.replace-with-upload");
        break;
    }
    return response;
  };

  const { titleLabel, bodyLabel, cancelLabel, confirmLabel, ariaLabel } =
    getDialogText();

  return (
    <ModalDialog isOpen={isOpen} onRequestDismiss={onCloseModal}>
      <ModalDialogContent aria-label={ariaLabel}>
        <ModalDialogNav>
          <ModalDialogCloseButton accessibleText={t("close")} />
        </ModalDialogNav>
        <ModalDialogHeader>{titleLabel}</ModalDialogHeader>
        <ModalDialogBody>{bodyLabel}</ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <Button
              width={isMobileView ? "full-width" : "standard"}
              onClick={onCloseModal}
            >
              {cancelLabel}
            </Button>
            <Button
              skin="primary"
              disabled={isLoading}
              width={isMobileView ? "full-width" : "standard"}
              onClick={() => handleOnClick()}
            >
              {confirmLabel}
            </Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  );
};
