export enum ERROR_TYPE {
  NONE,
  INVALID,
  REQUIRED,
  SIZE_UNAVAILABLE,
  INSUFFICIENT_STOCK,
  SIZE_UNSPECIFIED,
}

export const INFINITE_INVENTORY = 999999;

export const HARD_GOOD_INVENTORY_KEY = "hardGood";

export const TEAMS_ITEM_REFERENCE_TYPE = "Teams Name";

export const NO_INPUT_SIZE = "none";
